import { FC } from 'react';
import Image from 'next/image';
import { Button } from '@/components/buttons/Button';
import { Plus } from 'lucide-react';
import { Text } from '@/components/Text';
import { cn } from '@/lib/utils';
import crystalImage from '@/assets/images/crystal-image.png';
import { GemIcon } from 'lucide-react';
import { AnimatedCounter } from '@/components/AnimatedCounter';
import { useUserStore } from '@/stores/user';

type Props = {
  className?: string;
  amount: number;
  onClick: () => void;
};

export const GemButton: FC<Props> = ({ className, amount, onClick }) => {
  const { userStats } = useUserStore(s => s);
  return (
    <button
      onClick={onClick}
      className={cn(
      'inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-black-200 border border-white/20 rounded-md', 
      'hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        className)}
    >
      <div className="w-[24px] h-[24px] flex items-center justify-center w-10 h-10 mr-2 relative">
        {/* <div className="w-[24px] h-[24px] z-10 flex items-center justify-center w-10 h-10 bg-black-100 rounded-full"> */}
          <Image className="w-[20px] h-[20px]" src={crystalImage} alt="crystal" />
          {/* {userStats.subscription?.status === 'active' ? 
            <GemIcon className="w-[20px] h-[20px] text-blue" /> 
          : <GemIcon className="w-[20px] h-[20px] text-white" />} */}
        {/* </div>         */}
      </div>
      <Text className="transition-all duration-150 text-white group-active/button:text-black" variant="btn-1">
        <AnimatedCounter amount={amount} />
      </Text>
      <Plus className="w-4 h-4 ml-2 -mr-1"/>
    </button>
  );
};
