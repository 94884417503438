import { FC } from 'react';
import { Button } from '@/components/buttons/Button';
import { cn } from '@/lib/utils';

type Props = {
  className?: string;
  onClick: () => void;
};

export const MenuButton: FC<Props> = ({ className, onClick }) => {
  return (
    <Button
      onClick={onClick}
      className={cn('w-auto z-10', className)}
      wrapperClass="border border-white bg-black-200 group-active/button:bg-white p-[10px]"
    >
      <div className="grid grid-cols-2 gap-[5px]">
        <span className="w-[6px] h-[6px] rounded-full border border-white bg-white/[.5] group-active/button:bg-black/[.5] group-active/button:border-black"></span>
        <span className="w-[6px] h-[6px] rounded-full border border-white bg-white/[.5] group-active/button:bg-black/[.5] group-active/button:border-black"></span>
        <span className="w-[6px] h-[6px] rounded-full border border-white bg-white/[.5] group-active/button:bg-black/[.5] group-active/button:border-black"></span>
        <span className="w-[6px] h-[6px] rounded-full border border-white bg-white/[.5] group-active/button:bg-black/[.5] group-active/button:border-black"></span>
      </div>
    </Button>
  );
};
