import React, { useState } from 'react';
import useIsMobile from '@/hooks/useIsMobile';
import { useRouter } from 'next/router';
import { useAppStore } from '@/stores/app';
import { useIsAuthenticated } from '@/hooks/useIsAuthenticated';
import { useUserStore } from '@/stores/user';
import { Button } from '@/components/buttons/Button';
import { openModal } from '@/components/BaseModal';
import { Modals } from '@/types/modals';
import { Text } from '@/components/Text';
import CharacterGalleryIcon from '@/assets/icons/char-gallery-icon.svg';
import CreationIcon from '@/assets/icons/creation-icon.svg';
import CameraPortraitIcon from '@/assets/icons/camera-portrait.svg';
import GalleryIcon from '@/assets/icons/gallery-icon.svg';
import { GemIcon, MessageCircleMore, UserIcon, ChevronDown, CircleUserRound, LogOut } from 'lucide-react';
import posthog from 'posthog-js';

const SidebarSocial: React.FC = () => {
  const isMobile = useIsMobile();
  const toggleSidebar = useAppStore((s) => s.toggleSidebar);
  const { setSignInUiType, setIsSignInModalVisible, logOut } = useAppStore((s) => s);
  const { push } = useRouter();
  const isSignedIn = useIsAuthenticated();
  const { userStats } = useUserStore();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  

  const handleNavClick = (path: string) => {
    if (isMobile) {
      toggleSidebar();
    }
    push(path);
  };

  const onClickSignIn = () => {
    setSignInUiType('signinUI');
    setIsSignInModalVisible(true);
    openModal(Modals.SignIn);
    window.gtag && window.gtag('event', 'signin_attempt', { location: 'sidebar' });
    posthog.capture('signin_attempt', { location: 'sidebar' });
  };

  const onShowGallery = () => {
    if (isSignedIn) {
      handleNavClick('/images/collection');
    } else {
      onClickSignIn();
    }
  };

  const onMyCharacters = () => {
    handleNavClick('/creations');
  };

  const onCreateImages = () => {
    if (isSignedIn) {
      handleNavClick('/images/generate');
    } else {
      onClickSignIn();
    }
  };

  const onViewSubscription = () => {
    if (isSignedIn) {
      handleNavClick('/premium');
    } else {
      onClickSignIn();
    }
  };

  const handleLogout = () => {
    const options = window ? { callbackUrl: window.location.origin + '/' } : undefined;
    logOut(options);

    window.gtag && window.gtag('event', 'logged_out');
    posthog.capture('logged_out');
  };

  return (
    <div className="px-5 md:px-5 mt-2.5 mb-4">
      <Button
        onClick={() => handleNavClick('/')}
        className="w-full mb-2"
        wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
      >
        <CharacterGalleryIcon className="ml-1 mr-2" />
        <Text textColor="white" variant="btn-1">
          Explore
        </Text>
      </Button>

      <Button
        onClick={() => handleNavClick('/chat')}
        className="w-full mb-2"
        wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
      >
        <MessageCircleMore className="ml-1 mr-2 text-white" />
        <Text textColor="white" variant="btn-1">
          Chat
        </Text>
      </Button>

      <Button
        onClick={onMyCharacters}
        className="w-full mb-2"
        wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
      >
        <CreationIcon className="ml-2 mr-3" width={20} height={20} />
        <Text textColor="white" variant="btn-1">
          My AI
        </Text>
      </Button>

      <Button
        onClick={onShowGallery}
        className="w-full mb-2"
        wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
      >
        <GalleryIcon className="ml-2 mr-3" width={20} height={20} />
        <Text textColor="white" variant="btn-1">
          Gallery
        </Text>
      </Button>

      <Button
        onClick={onCreateImages}
        className="w-full mb-2"
        wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
      >
        <CameraPortraitIcon className="ml-2 mr-3" width={20} height={20} />
        <Text textColor="white" variant="btn-1">
          Generate Image
        </Text>
      </Button>

      {userStats && userStats.subscription?.status !== 'active' && (
        <Button
          onClick={onViewSubscription}
          className="w-full mb-2"
          wrapperClass="py-[12px] border border-white bg-pink-gr group-active/button:bg-white p-[13px] items-left justify-start"
        >
          <GemIcon className="ml-2 mr-3 text-white" width={20} height={20} />
          <Text textColor="white" variant="btn-1">
            Premium
          </Text>
        </Button>
      )}

      {isSignedIn && (
        <Button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="w-full mb-2"
          wrapperClass="py-[12px] border border-white bg-black-200 group-active/button:bg-white p-[13px] items-left justify-start"
        >
          <UserIcon className="ml-1 mr-2 text-white" />
          <Text textColor="white" variant="btn-1">
            My Account
          </Text>

          <ChevronDown className="absolute right-2 text-white"></ChevronDown>
        </Button>
      )}

      {(isSignedIn && isDropdownOpen) && (
        <div 
          className="w-aut mb-5 -mt-2 origin-top-right bg-black-100 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-[25]"
        >
          <div className="pl-5 px-1 py-1">
            <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white"
              onClick={(e) => handleNavClick('/profile')}
            >
              <CircleUserRound className="w-5 h-5 mr-2" /> Profile
            </button>

            <button className="group flex flex-grow rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white mr-10"
              onClick={(e) => handleNavClick('/premium')}
            >
              <GemIcon className="w-5 h-5 mr-2" /> Subscription
            </button>

            <button className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-gray-900 hover:bg-black-200 hover:text-white"
              onClick={handleLogout}
            >
              <LogOut className="w-5 h-5 mr-2" /> Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarSocial;
