import { cn } from '@/lib/utils';
import Link from 'next/link';
import { Text } from '@/components/Text';
import { FC } from 'react';

type Props = {
  className?: string;
  href: string;
  title: string;
  onClick?: () => void;
};

export const CustomLink: FC<Props> = ({ className, href, title, onClick }) => {
  return (
    <Link className={cn('', className)} href={href} onClick={onClick}>
      <Text className="text-white/[.5] hover:text-white transition-all duration-300" variant={'btn-2'}>
        {title}
      </Text>
    </Link>
  );
};
