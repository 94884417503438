import { FC } from 'react';
import { cn } from '@/lib/utils';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  Icon: FC<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
}

export const SocialMediaButton: FC<Props> = ({ className, onClick, Icon }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        'w-[42px] h-[42px] flex items-center justify-center relative rounded-lg border border-white/[.2] transition-all duration-150 bg-black-200/[.2] hover:bg-white',
        className,
      )}
      aria-label="button"
    >
      <Icon className="transition-all duration-150" />
    </button>
  );
};
