import React, { FC, useMemo, useState } from 'react';
import SidebarSocial from './SidebarSocial';
import SidebarNavigation from './SidebarNavigation';
import { cn } from '@/lib/utils';

export type SidebarProps = {
  isOpen: boolean;
  toggleSidebar: () => void;
};

export const Sidebar: FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  return (
    <>
      <aside
        className={cn(
          'h-full overflow-hidden transition-all ease-out duration-300 z-[22] lg:z-0 w-80 md:w-auto fixed lg:static',
          isOpen ? 'translate-x-0 md:translate-x-0' : '-translate-x-full md:translate-x-0',
        )}
      >
        <div className="w-full h-full flex">
          <div
            className={cn(
              'overflow-hidden transition-all ease-out duration-300 bg-black-100',
              isOpen ? 'w-full md:max-w-80 translate-x-0' : 'md:max-w-0 -translate-x-full',
            )}
          >
            <div className="h-full flex flex-col overflow-hidden w-full md:w-75 mt-3 pt-16 md:pt-0">

              <SidebarNavigation />

              <div className="flex-grow px-5 md:px-8"/>
              
              <SidebarSocial />

            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
