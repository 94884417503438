import { FC } from 'react';

type Props = {
  className?: string;
  pathClassName?: string;
};

export const MenuArrowsIcon: FC<Props> = ({ className, pathClassName }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 17L7.5 12L12.5 7"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
    <path
      opacity="0.5"
      d="M18.5 17L13.5 12L18.5 7"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
