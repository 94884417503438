

import React from 'react';
import { cn } from '@/lib/utils';
import { Text } from './Text';
import Image from 'next/image';
import crystalImage from '@/assets/images/crystal-image.png';

interface PricingProps {
    title: string;
    price: number;
    className?: string;
}

const PricingItem = ({ title, price, className }: PricingProps) => (
    <div className={cn("w-full flex justify-between", className)}>
        <Text className="group flex rounded-md items-center w-full px-2 py-2 text-sm text-white opacity-75">
            {title}
        </Text>
        <div className="flex items-center">
            <Text className="text-btn-2 md:text-btn-1" textColor="white" variant="btn-1">
            {price > 0 ? price : 'Free'}
            </Text>
            {price > 0 ? (
                <Image className="w-[15px] h-[15px] ml-2 mr-5" src={crystalImage} alt="crystal" />
            ) : (
                <Text className="ml-2 text-white" variant="btn-1"></Text>
                // <Text className="w-[15px] h-[15px] ml-2 mr-1" variant="btn-1" style={{ width: '15px' }}>&nbsp;</Text>
            )}
        </div>
    </div>
);

export default PricingItem;
