import { useState } from 'react';
import Image, { ImageProps } from 'next/image';
import { cn } from '@/lib/utils';

interface Props extends ImageProps {
  skeletonClassName?: string;
  onLoaded?: () => void;
}

export function SkeletonImage({ onLoaded, ...props }: Props) {
  const [loaded, setLoaded] = useState(false);

  function onLoad() {
    setLoaded(true);

    if (onLoaded) {
      onLoaded();
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      onLoad={onLoad}
      className={cn('w-[${props.width}px] h-[${props.height}px]', props.className, !loaded && `skeleton bg-black-100`)}
    />
  );
}
